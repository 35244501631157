export const trackPageEvent = (win: Window, eventName: string, additionalData = {}) => {
  win._uxa.push(['trackPageview', eventName, { ...additionalData }]);
};

export const trackPopinEvent = (win: Window, popinName: string, isOpen: boolean) => {
  win._uxa.push(['setQuery', win.location.search + (win.location.search ? '&' : '?') + popinName]);

  // Track pageview for opening the popin
  if (isOpen) {
    win._uxa.push(['trackPageview', win.location.pathname + win.location.hash.replace('#', '?__')]);
  } else {
    // Track pageview for closing the popin
    win._uxa.push(['setQuery', win.location.search]); // Reset the query
    win._uxa.push(['trackPageview', win.location.pathname + win.location.hash.replace('#', '?__')]);
  }
};

export const trackPurchaseEvent = (win: Window, transactionId: string, revenue: number, currency = 'SAR') => {
  // Push transaction info into CS global object
  win._uxa.push([
    'ec:transaction:create',
    {
      id: transactionId, // Transaction ID (string, up to 40 characters)
      revenue: revenue.toFixed(2), // Revenue, formatted to 2 decimal places
      currency: currency, // Currency (ISO 4217 value, optional)
    },
  ]);

  // Send the transaction to Contentsquare
  win._uxa.push(['ec:transaction:send']);
};
